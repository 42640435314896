import { doc, collection, onSnapshot } from 'firebase/firestore'
import { db } from '../../../firebase/config'
import '../../../global'
import { useEffect, useState } from 'react'
import { formatRestaurantData } from '../getRestaurantData'
import { DATABASE_NAME } from '@env'
import _ from 'lodash'
import { useAppDispatch, useAppSelector } from '../../../state/hooks'
import { setDataState } from '../../../state/Slices/dataSlice'

const useRestaurantListener = () => {
	const { loadedData, restaurants } = useAppSelector(state => state.data)

	const [restaurantData] = useState({})
	const dispatch = useAppDispatch()

	useEffect(() => {
		const restaurantRef = collection(doc(db, DATABASE_NAME, global.org), 'Restaurants')

		const unsubscribe = onSnapshot(restaurantRef, snapshot => {
			snapshot.docChanges().forEach(change => {
				const data = change.doc.data()
				if (change.type === 'modified' && data !== undefined && !data.isDeleted && data.isEnabled) {
					const newRestaurants = [...restaurants]

					const restoData = formatRestaurantData(data)
					// console.log('inside restaurant listener', restoData.name)

					// Update restaurantData state
					restaurantData[data.id] = restoData
					const _ = require('lodash')

					const index = newRestaurants.findIndex(item => item.id === restoData.id)

					if (index !== -1) {
						newRestaurants[index] = restoData
					}

					const updatedData = _.merge({}, loadedData, restaurantData, {
						restaurants: newRestaurants,
					})

					dispatch(setDataState({ restaurants: newRestaurants, loadedData: updatedData }))

					//rerender screen
					// if (Object.keys(restaurantData).length > 0 && !_.isEqual(updatedData, loadedData)) {
					// 	//setReset([])
					// }
				}
			})
		})

		// Clean up function
		return () => {
			unsubscribe()
		}
	}, [])
}

export default useRestaurantListener
