import { ModifierList } from '../../../state/types'
import Formatter from '../../../helpers/Formatter'

export const findValidModListIds = modList => {
	const modListIds = []
	for (const modifierList of modList) {
		const parentModifierId = modifierList.id.split('-')[1]
		const showNested = modList.some(modifierList =>
			modifierList.options.some(modifier => modifier.isSelected && modifier.id === parentModifierId)
		)

		if (modifierList.isNested && !showNested) {
			continue
		}

		modListIds.push(modifierList.id)
	}

	return modListIds
}

export const getModListQuantity = (modList, qty, list) => {
	const selectedModList = modList.find(modList => modList.id === list.id)
	const modListQuantity = selectedModList.options.reduce(
		(acc, option) => (option?.quantity ?? 0) + acc,
		0
	)
	return modListQuantity || qty
}

export function getDiscountPrice(coupon, itemData, quantity) {
	var discount = 0
	if (coupon.type === 'freeItem') {
		discount = itemData.price / 100
	} else if (coupon.type === 'BOGO') {
		discount =
			(itemData.price * (coupon.itemSavePercent / 100) * totalBogoLimit(quantity, coupon.limit)) /
			100
	}

	return discount
}

export function getCombinedModPrice(modlist: ModifierList[]) {
	let price = 0
	modlist.forEach(list => {
		list.options.forEach(mod => {
			if (mod.isSelected) {
				const quantity = mod.quantity || 1
				price += mod.price * quantity
			}
		})
	})
	return price
}

export function generateUniqueId() {
	const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
	let id = ''
	for (let i = 0; i < 20; i++) {
		id += chars.charAt(Math.floor(Math.random() * chars.length))
	}
	return id
}

export function countSelected(list) {
	let count = 0
	if (list !== undefined) {
		list.options.forEach(mod => {
			if (mod.isSelected) {
				if (mod.quantity) {
					count += mod.quantity
				} else {
					count += 1
				}
			}
		})
		return count
	}
	return 0
}

export function countQtySelected(list, quantitySelected) {
	if (list !== undefined) {
		const qty = quantitySelected
		if (list.selectionType === 'SINGLE' || (list.min === 1 && list.max === 1)) {
			return 1
		} else if (qty === undefined) {
			return 0
		} else {
			return qty
		}
	}
	return 0
}

export function showModPrice(price) {
	if (price !== 0) {
		return Formatter.currencyFormat(price / 100)
	}
}

function totalBogoLimit(qty, bogoLimit) {
	if (bogoLimit > -1) {
		if (bogoLimit < Math.floor(qty / 2)) {
			return bogoLimit
		} else {
			return Math.floor(qty / 2)
		}
	} else {
		return Math.floor(qty / 2)
	}
}
