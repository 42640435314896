import React, { useState, useEffect } from 'react'
import {
	Dimensions,
	View,
	KeyboardAvoidingView,
	ScrollView,
	Platform,
	TouchableOpacity,
	Image,
} from 'react-native'
import { Text } from '../../../components/Themed'
import { DisplayOrder } from '../../../components/OrderCart/DisplayOrder'
import { Spacer } from '../../../components/Spacer'
import GlobalStyle, {
	MAX_CHECKOUT_WIDTH,
	mediumTextSize,
	titleTextSize,
	IS_LANDSCAPE,
} from '../../../constants/GlobalStyle'
import { styles } from '../Helpers/style'
import { OrderModals } from '../Modals/PlaceOrder/OrderModals'
import Colors from '../../../constants/Colors'
import { alertResponse, alertResponseText } from '../../../components/Alerts/Alerts'
import { AddPromoView } from '../Modals/AddPromo/AddPromoView'
import ScheduleOrder from '../../../components/ScheduleHours/ScheduleOrderModal'
import { PromoModal } from '../Modals/AddPromo/PromoModal'
import { AddPromo } from '../Modals/AddPromo/AddPromo'
import { CartHeader } from './Header'
import CartFooter, { KioskCartFooter } from './Footer'
import { NotesInput } from './NotesInput'
import NavFooter from '../../../components/Footers/NavFooter'
import {
	OrderNoteBox,
	PaymentMethodBox,
	PromoCodeBox,
	PickupTimeBox,
	RewardsBox,
	ChangeLocationBox,
	DeliveryDetailsBox,
	PickupPointDetailsBox,
} from './SelectorBox'
import { responsiveHeight, responsiveWidth } from 'react-native-responsive-dimensions'
import { PaymentMethodModal } from '../Modals/PaymentMethods/PaymentMethodModal'
import { auth } from '../../../firebase/config'
import { WebHeader } from '../../../components/Headers/WebHeader'
import CopyrightFooter from '../../../components/Footers/CopyrightFooter'
import { MaterialCommunityIcons } from '@expo/vector-icons'
import { ForwardButton } from '../../../components/Headers/UIButtons'
import { logRemoveFromCartAnalytics } from '../../../firebase/analytics'
import { LocationSelectModal } from '../../../components/LocationSelect'
import POSNavBar from '../../../navigation/POSNavBar'
import useStripeDigitalWallet from '../Hooks/useStripeDigitalWallet.web'
import DeliveryPickupToggle from '../../../components/Delivery/DeliveryPickupToggle'
import OrderBanner from '../../../components/Delivery/OrderBanner'
import Banner from '../../../components/Banner'
import { useAppDispatch, useAppSelector } from '../../../state/hooks'
import { prepText } from '../../../helpers/prepText'
import { areAllRestaurantsInCartOpen } from '../../../helpers/restaurantHours'
import { removeItemFromCart, setPersistedState } from '../../../state/Slices/persistedSlice'
import { setCheckoutState } from '../../../state/Slices/checkoutSlice'
import NoItemsInCart from './NoItems'

export function CheckoutView({
	navigation,
	priceSet,
	goHome,
	getSchedInterval,
	tipFuncData,
	promoFuncData,
	togglePromoModal,
	toggleConfirmModal,
	togglePaymentMethodModal,
}) {
	const dispatch = useAppDispatch()

	const {
		items,
		couponCode,
		orderPlaced,
		bagFeeEnabled,
		bagFee,
		orderNote,
		couponName,
		cardElementVisible,
		visibleCouponCount,
		isOpen,
		loading,
		isRestaurantSnoozed,
		deliveryAmountToSpend,
	} = useAppSelector(state => state.checkout)

	const { delivery, deliveryEnabled, paymentProvider, allowOrdersOutsideOrgHours, localTimezone } =
		useAppSelector(state => state.settings)
	const { loadedData, restaurants, organizationLogo, restaurantList } = useAppSelector(
		state => state.data
	)
	const {
		cart,
		cartPrep,
		selectedLocation,
		isDelivery,
		deliveryEstimate,
		deliveryEstimateList,
		redeemedRewards,
		pickupPointModalVisible,
	} = useAppSelector(state => state.persisted)
	const { prices } = useAppSelector(state => state.checkout)
	const { paymentMethods, userLocation } = useAppSelector(state => state.user)

	const orgLogoDark = loadedData[global.org].organizationLogoDark
		? loadedData[global.org].organizationLogoDark
		: ''

	let deliveryEstimateForRestaurant: any = deliveryEstimate

	if (
		deliveryEstimate &&
		cart &&
		deliveryEstimateList?.length > 0 &&
		cart.length > 0 &&
		!loadedData[global.org].singleBrandEnabled
	) {
		const estimate = deliveryEstimateList.find(est => est.restaurantId === cart[0].rId)
		if (estimate) {
			deliveryEstimateForRestaurant = estimate
		}
	}

	const deliveryTime =
		deliveryEstimateForRestaurant && deliveryEstimateForRestaurant.estimate
			? deliveryEstimateForRestaurant.estimate.timeToDeliver
			: 60

	const [showOrderNotePopup, setShowOrderNotePopup] = useState(false)

	const [locationsModalVisible, setLocationsModalVisible] = useState(false)

	const [showDeliveryAddressInput, setShowDeliveryAddressInput] = useState(false)

	const hoursRef = React.useRef<any>()

	const descriptor = global.pos ? 'the' : 'your'

	const allowSave = Platform.OS === 'web' && auth.currentUser === null ? false : true

	const singleBrandEnabled = loadedData[global.org].singleBrandEnabled || false

	let addressSpace = ''
	const address =
		selectedLocation && selectedLocation.address
			? selectedLocation.address
			: { addressLine1: '', addressLine2: '' }
	if (address.addressLine2 !== '') {
		addressSpace = ' '
	}
	const formattedAddressLine = `${address.addressLine1} ${
		address.addressLine2 ? address.addressLine2 : ''
	}`

	const rIdToken = cart.length > 0 ? cart[0].rId : selectedLocation ? selectedLocation.id : null
	if (Platform.OS === 'web') {
		useStripeDigitalWallet()
	}

	useEffect(() => {
		if (isDelivery && (!userLocation || !userLocation.longAddress)) {
			setShowDeliveryAddressInput(true)
		}
	}, [isDelivery])

	function deletePopup(item) {
		alertResponse(
			'Remove ' + item.name + '?',
			"Are you sure you'd like to remove this item from " + descriptor + ' cart?',
			'Cancel',
			'Remove',
			'cancel',
			'default',
			() => {
				return
			},
			() => {
				removeItem(item)
			}
		)
	}

	function removeItem(item) {
		logRemoveFromCartAnalytics((item.ppu * item.qty) / 100, {
			item_id: item.id,
			item_name: item.name,
			item_location_id: item.rId,
			quantity: item.qty,
			price: (item.ppu * item.qty) / 100,
			item_category: item.menuItemCategory,
		})

		// Check if loyalty reward is being removed
		if (item.appliedCoupon) {
			//remove from redeemedRewards
			const newRewards = redeemedRewards.filter(x => x.rewardId !== item.appliedCoupon)
			dispatch(setPersistedState({ redeemedRewards: newRewards }))
		}
		if (item.isFree) {
			dispatch(setCheckoutState({ isFreeItemDiscount: false }))
		}
		dispatch(
			setCheckoutState({
				items: items.filter(x => {
					return !(x.id === item.id && x.idempotencyKey === item.idempotencyKey)
				}),
			})
		)

		dispatch(removeItemFromCart(item.id, item.idempotencyKey))
		if (cart.length < 1) {
			goHome(false)
		} else {
			navigation.navigate('TabCheckout')
		}
	}

	const setPickupPointModalVisible = value => {
		dispatch(setPersistedState({ pickupPointModalVisible: value }))
	}

	const cartOpen = areAllRestaurantsInCartOpen(
		cart,
		restaurants,
		loadedData[global.org].hours ?? null,
		allowOrdersOutsideOrgHours,
		localTimezone
	)

	const PickupDisplay = () => {
		return (
			<TouchableOpacity
				style={{ paddingTop: 10 }}
				onPress={() => {
					hoursRef.current.open()
				}}
			>
				{prepText(cartPrep, isDelivery)}
				<Text
					style={{
						color: Colors.greyscale[5],
						fontSize: mediumTextSize,
					}}
				>
					{prepText(cartPrep, isDelivery).toUpperCase()}
				</Text>
			</TouchableOpacity>
		)
	}

	const freeDeliveryPrice =
		deliveryEstimateForRestaurant && deliveryEstimateForRestaurant.estimate
			? Math.round(
					deliveryEstimateForRestaurant.estimate.fee * delivery.freeDeliveryMultiplier +
						delivery.minSpend
				) / 100
			: -1

	const filteredRestaurantsByItems = restaurantList.filter(restaurant =>
		items.some(item => item.rId === restaurant.id)
	)

	function canCheckoutForDelivery() {
		const firstRestaurantId = cart[0].rId // Get the restaurantId of the first object

		// check if restaurant id has a valid estimate
		const matchingEstimate = deliveryEstimateList.find(
			est => est.restaurantId === firstRestaurantId
		)
		if (matchingEstimate) {
			return cart.every(item => item.rId === firstRestaurantId)
		} else {
			return false
		}
	}

	return (
		<>
			{orderPlaced ? (
				<>
					<View style={styles.fullScreen}></View>
				</>
			) : (
				<View></View>
			)}

			<OrderBanner navigation={navigation} />

			{filteredRestaurantsByItems && <ScheduleOrder hoursRef={hoursRef} />}
			{singleBrandEnabled && (
				<LocationSelectModal
					visible={locationsModalVisible}
					setVisible={setLocationsModalVisible}
					navigation={navigation}
					rIdToken={rIdToken}
					isCheckout={true}
				/>
			)}

			{/* payment method modal */}

			<PaymentMethodModal
				toggleModal={togglePaymentMethodModal}
				height={Dimensions.get('window').height * 0.77}
				toggleConfirmModal={toggleConfirmModal}
				allowSave={allowSave}
			/>

			{Platform.OS === 'web' && !orderPlaced && (
				<WebHeader logo={organizationLogo} navigation={navigation} />
			)}

			<KeyboardAvoidingView
				behavior={'height'}
				style={[GlobalStyle.checkoutContainer, { paddingHorizontal: 0 }]}
			>
				{/* promo modal */}
				<View>
					<PromoModal
						togglePromoModal={togglePromoModal}
						height={visibleCouponCount <= 0 ? 400 : Dimensions.get('window').height * 0.77}
					>
						<AddPromoView toggleModal={togglePromoModal} showSelections={visibleCouponCount > 0}>
							<AddPromo promoFuncData={promoFuncData} staffMeal={false} />
						</AddPromoView>
					</PromoModal>
				</View>

				{/* modal (confirm order) code */}
				<OrderModals
					toggleConfirmModal={toggleConfirmModal}
					navigation={navigation}
					tipFuncData={tipFuncData}
					goHome={goHome}
					getSchedInterval={getSchedInterval}
					promoFuncData={promoFuncData}
				/>

				{/* default view */}

				<ScrollView>
					{Platform.OS !== 'web' && (
						<>
							<View
								style={{
									backgroundColor: Colors.greyscale[2],
									height: 400,
									marginTop: -400 + responsiveHeight(20),
								}}
							></View>

							<CartHeader
								onPress={() => {
									if (singleBrandEnabled && global.kiosk) {
										navigation.navigate('HomeStack', { screen: 'Menu', params: { rId: rIdToken } })
									} else {
										navigation.navigate('HomeStack', { screen: 'RestaurantSelect' })
									}
								}}
								organizationLogo={orgLogoDark ? orgLogoDark : organizationLogo}
							/>
						</>
					)}

					<View
						style={{
							alignSelf: 'center',
							width: responsiveWidth(90),
							maxWidth: MAX_CHECKOUT_WIDTH,
							marginHorizontal: '5%',
							display: cart.length ? 'flex' : 'none',
						}}
					>
						{isDelivery &&
						deliveryAmountToSpend === 0 &&
						delivery.freeDeliveryMultiplier !== -1 &&
						!isNaN(deliveryAmountToSpend) &&
						!isNaN(delivery.freeDeliveryMultiplier) &&
						deliveryEstimateForRestaurant &&
						deliveryEstimateForRestaurant.estimate &&
						deliveryEstimateForRestaurant.estimate.fee > 0 &&
						Math.round(freeDeliveryPrice - prices.subTotal) > 0 ? (
							<Banner
								text={`Add $${Math.round(freeDeliveryPrice - prices.subTotal).toFixed(
									2
								)} to your cart to get free delivery!`}
							/>
						) : isDelivery &&
						  deliveryAmountToSpend === 0 &&
						  delivery.freeDeliveryMultiplier !== -1 &&
						  !isNaN(deliveryAmountToSpend) &&
						  !isNaN(delivery.freeDeliveryMultiplier) &&
						  deliveryEstimateForRestaurant &&
						  deliveryEstimateForRestaurant.estimate &&
						  deliveryEstimateForRestaurant.estimate.fee > 0 ? (
							<Banner text={`Congratulations! You've earned free delivery on your order!`} />
						) : (
							<></>
						)}
						<View
							style={{
								paddingTop: Platform.OS === 'web' ? responsiveHeight(6) : 30,
							}}
						>
							<View
								style={{
									flexDirection: 'row',
									justifyContent: 'space-between',
									paddingBottom: global.pos ? 0 : 25,
									paddingTop: deliveryEnabled ? 15 : 0,
								}}
							>
								<Text
									style={[
										GlobalStyle.titleText,
										{
											paddingBottom: 0,
											color: global.orgColor2,
										},
									]}
								>
									{global.pos ? 'ORDER SUMMARY' : 'MY ORDER'}
								</Text>
								<View
									style={{
										paddingTop: 5,
										display:
											!loadedData[global.org].singleBrandEnabled && Platform.OS === 'web'
												? 'none'
												: 'flex',
									}}
								>
									<DeliveryPickupToggle showChange={false} showAddress={false} />
								</View>
							</View>

							{global.pos && <PickupDisplay />}
							<Text
								style={{
									fontSize: titleTextSize - 6,
									fontWeight: '300',
									color: Colors.greyscale[10],
									paddingVertical: IS_LANDSCAPE ? '2.5%' : '5%',
									display: global.kiosk ? 'flex' : 'none',
								}}
							>
								READY TO CHECKOUT?
							</Text>
						</View>

						<View>
							<View style={{ display: items.length > 0 ? 'flex' : 'none' }}>
								<View style={{ marginHorizontal: -15 }}>
									<DisplayOrder
										items={items}
										prices={prices}
										isCheckout={true}
										isConfirmed={false}
										navigation={navigation}
										deletePopup={deletePopup}
										loadedData={loadedData}
										trackingURL={null}
									/>
								</View>
								<Spacer size={10} />
							</View>
							<View
								style={{
									display: items.length <= 0 && cart.length > 0 ? 'flex' : 'none',
								}}
							>
								<Image
									style={[
										styles.loadImg,
										{
											width: global.kioskV2 ? 120 : global.kiosk ? 180 : 100,
											height: global.kioskV2 ? 120 : global.kiosk ? 180 : 100,
										},
									]}
									source={require('../../../assets/images/loadImg.gif')}
								/>
							</View>

							<Spacer size={2} />
						</View>
					</View>
					<View style={{ display: cart.length ? 'flex' : 'none' }}>
						{global.kiosk ? null : (
							<>
								<View style={styles.borderLine}></View>
								{loadedData[global.org].loyaltyEnabled && <RewardsBox navigation={navigation} />}

								<View
									style={{
										marginHorizontal: '5%',
										alignSelf: 'center',
										width: responsiveWidth(90),
										maxWidth: MAX_CHECKOUT_WIDTH,
									}}
								>
									{singleBrandEnabled && restaurantList.length > 1 && !isDelivery && (
										<ChangeLocationBox
											toggleLocationModal={() => {
												setLocationsModalVisible(!locationsModalVisible)
											}}
											currentAddress={formattedAddressLine}
										/>
									)}

									<DeliveryDetailsBox
										showModal={showDeliveryAddressInput}
										setShowModal={setShowDeliveryAddressInput}
									/>

									<PickupPointDetailsBox
										showModal={pickupPointModalVisible}
										setShowModal={setPickupPointModalVisible}
										numOfRestaurants={filteredRestaurantsByItems?.length}
									/>

									<PickupTimeBox
										toggleTimeModal={() => {
											hoursRef.current.open()
										}}
										pickupText={prepText(cartPrep, isDelivery).toUpperCase()}
										isOpen={cartOpen}
									/>

									<PaymentMethodBox
										onPress={
											auth.currentUser === null ? toggleConfirmModal : togglePaymentMethodModal
										}
									/>
									<PromoCodeBox
										togglePromoModal={togglePromoModal}
										couponCode={couponCode}
										couponName={couponName}
									/>
									<OrderNoteBox />
								</View>
							</>
						)}
					</View>

					<View style={{ height: 50 }}></View>

					{!global.kiosk && cart.length > 0 && (
						<CartFooter
							prices={prices}
							style={{ paddingBottom: Dimensions.get('window').height * 0.1 }}
							showBanner={
								isDelivery && deliveryAmountToSpend !== 0 && !isNaN(deliveryAmountToSpend)
							}
						/>
					)}
					{Platform.OS === 'web' && cart.length > 0 && !orderPlaced && (
						<>
							<NavFooter
								loading={loading || (isDelivery && deliveryAmountToSpend !== 0)}
								cartBar={true}
								navigation={navigation}
								isRestaurantSnoozed={isRestaurantSnoozed}
								price={prices.total}
								onCartPress={() => {
									const canAddToCart =
										loadedData[global.org].singleBrandEnabled || !deliveryEnabled || !isDelivery
											? true
											: canCheckoutForDelivery()
									if (!canAddToCart) {
										alertResponseText(
											'Error processing your delivery',
											'Please check your cart and try again.'
										)
									} else if ((prices.deliveryFee === -1 || deliveryTime === -1) && isDelivery) {
										setShowDeliveryAddressInput(true)
									} else if (isOpen || cartPrep.isScheduled) {
										if (
											auth.currentUser &&
											!global.kiosk &&
											paymentMethods &&
											paymentMethods.length <= 0 &&
											paymentProvider === 'stripe'
										) {
											dispatch(setCheckoutState({ cardElementVisible: !cardElementVisible }))
										} else {
											toggleConfirmModal()
										}
									} else {
										if (hoursRef && hoursRef.current) {
											hoursRef.current.open()
										}
									}
								}}
							/>

							<CopyrightFooter />
						</>
					)}
				</ScrollView>
			</KeyboardAvoidingView>
			{global.kiosk && cart.length > 0 && (
				<>
					<KioskCartFooter
						prices={prices}
						cost={bagFee.totalAmount}
						priceSet={priceSet}
						couponCode={couponCode}
						bagFeeEnabled={bagFeeEnabled}
						openPromo={togglePromoModal}
						setShowOrderNotes={setShowOrderNotePopup}
						checkoutFunc={toggleConfirmModal}
					/>
				</>
			)}
			{global.pos ? <POSNavBar navigation={navigation} screenName={'Cart'} /> : null}
			{!global.kiosk && Platform.OS !== 'web' && cart.length > 0 && !orderPlaced && (
				<NavFooter
					isRestaurantSnoozed={isRestaurantSnoozed}
					loading={loading || (isDelivery && deliveryAmountToSpend !== 0)}
					cartBar={true}
					navigation={navigation}
					price={prices.total}
					onCartPress={() => {
						if ((prices.deliveryFee === -1 || deliveryTime === -1) && isDelivery) {
							setShowDeliveryAddressInput(true)
						} else if (global.kiosk || isOpen || cartPrep.isScheduled) {
							if (
								auth.currentUser &&
								!global.kiosk &&
								paymentMethods &&
								paymentMethods.length <= 0
							) {
								if (paymentProvider === 'square') {
									toggleConfirmModal()
								} else {
									dispatch(setCheckoutState({ cardElementVisible: !cardElementVisible }))
								}
							} else {
								toggleConfirmModal()
							}
						} else {
							if (hoursRef && hoursRef.current) {
								hoursRef.current.open()
							}
						}
					}}
				/>
			)}
			{showOrderNotePopup && (
				<NotesInput
					orderNote={orderNote}
					onClose={value => {
						dispatch(setCheckoutState({ orderNote: value }))
						setShowOrderNotePopup(false)
					}}
				/>
			)}

			{cart.length <= 0 && <NoItemsInCart navigation={navigation} />}
		</>
	)
}
