import React, { useCallback, useEffect, useState } from 'react'
import {
	TouchableOpacity,
	ScrollView,
	Image,
	Modal,
	TouchableWithoutFeedback,
	StyleSheet,
} from 'react-native'
import { OrganizationLogoAddressPage } from '../../components/OrganizationLogoHeader'
import { formStyle, smallTextSize } from '../../constants/GlobalStyle'
import { MaterialCommunityIcons } from '@expo/vector-icons'
import { getFunctions, httpsCallable } from 'firebase/functions'
import { auth } from '../../firebase/config'
import { AddressCard } from './AddressCard'
import DeliveryPickupToggle from './DeliveryPickupToggle'
import { getDeliveryEstimate } from '../../firebase/fbDataRetrieval/RetrieveLocation'
import { add } from 'date-fns'
import { parsePrepTime } from '../../helpers/prepTimeFunctions'
import { Text, View } from '../Themed'
import { alertResponse } from '../Alerts/Alerts'
import Colors from '../../constants/Colors'
import Banner from '../Banner'
import { useAppDispatch, useAppSelector } from '../../state/hooks'
import { setUserState } from '../../state/Slices/userSlice'
import {
	addNewDeliveryEstimates,
	DeliveryEstimate,
	setPersistedState,
} from '../../state/Slices/persistedSlice'
import AddressForm from './AddressForm'
import ExitIcon from '../Headers/UIButtons'

export default function NewAddressModal({ modalVisible, setModalVisible }) {
	const dispatch = useAppDispatch()
	const { loadedData, restaurants, organizationLogo, userCurrentLocation } = useAppSelector(
		state => state.data
	)
	const { deviceHasInternet, userData, userLocation } = useAppSelector(state => state.user)
	const { activeRID, isDelivery, contactlessDropoff, deliveryEstimate } = useAppSelector(
		state => state.persisted
	)
	const { delivery, isSingleBrand } = useAppSelector(state => state.settings)

	const [showSavedAddresses, setShowSavedAddresses] = useState(true)
	const [showNewAddressForm, setShowNewAddressForm] = useState(false)
	const [saveAddress, setSaveAddress] = useState(auth.currentUser ? true : false)
	const [isLoading, setIsLoading] = useState(false)
	const [showInvalidAddress, setShowInvalidAddress] = useState(false)

	const orgLogoDark = loadedData[global.org]?.organizationLogoDark || ''
	const userId = auth.currentUser ? auth.currentUser.uid : ''
	const deliveryAddresses = userData?.user?.deliveryAddresses || []
	const availableRestaurants = restaurants.filter(restaurant => restaurant.isSnoozed === false)
	const prepTime = loadedData[activeRID]?.prepTime ?? 15

	const functions = getFunctions()

	useEffect(() => {
		if (isDelivery && !userLocation && deliveryAddresses.length > 0) {
			setModalVisible(true)
		} else if (isDelivery && !userLocation) {
			setShowNewAddressForm(true)
			setModalVisible(true)
		} else if (!isDelivery) {
			setModalVisible(false)
		}
	}, [isDelivery, userLocation, deliveryAddresses])

	useEffect(() => {
		setShowSavedAddresses(deliveryAddresses.length > 0)
		setShowNewAddressForm(deliveryAddresses.length <= 0)
	}, [deliveryAddresses])

	function splitFee(fee) {
		if (delivery.orgCoverageType === 'percent') {
			return fee * (delivery.orgCoverage / 100)
		} else if (fee - delivery.orgCoverage >= 0) {
			return fee - delivery.orgCoverage
		} else {
			return 0
		}
	}

	const handleDeliveryEstimate = async (userAddress: string) => {
		setIsLoading(true)
		const batchSize = 15
		const batches = []
		for (let i = 0; i < availableRestaurants.length; i += batchSize) {
			const batch = availableRestaurants.slice(i, i + batchSize)
			if (batch) batches.push(batch)
		}

		const processBatch = async batch => {
			const restaurantIds = batch.map(restaurant => restaurant.id)
			try {
				const estimates: any = await getDeliveryEstimate(
					userAddress,
					add(new Date(), parsePrepTime('P0DT0H' + prepTime + 'M0S')),
					null,
					delivery.isSandbox,
					restaurantIds
				)
				const successfulEstimates = estimates.success

				const newEstimates: DeliveryEstimate[] = successfulEstimates.map((estimate, index) => ({
					restaurantId: restaurantIds[index],
					userAddress: userAddress,
					estimate: {
						fee: splitFee(estimate?.fee) || -1,
						timeToDeliver: estimate?.timeToDeliver || -1,
						totalFee: estimate?.fee || -1,
					},
				}))

				if (isSingleBrand) {
					const estimate = successfulEstimates[0]

					dispatch(
						setPersistedState({
							deliveryEstimate: {
								restaurantId: activeRID,
								userAddress: userAddress,
								estimate: {
									fee: splitFee(estimate?.fee) || -1,
									timeToDeliver: estimate?.timeToDeliver || -1,
									totalFee: estimate?.fee || -1,
								},
							},
						})
					)
				} else if (newEstimates?.length > 0) dispatch(addNewDeliveryEstimates(newEstimates))

				const allEstimatesFail = newEstimates.every(
					estimate => estimate.estimate.fee === -1 && estimate.estimate.timeToDeliver === -1
				)

				if (allEstimatesFail) {
					setShowInvalidAddress(true)
				} else {
					setShowInvalidAddress(false)
					setModalVisible(false)
				}
			} catch (error) {
				console.error('Error processing batch of restaurant IDs:', error)
			}
		}

		if (batches.length > 0) {
			await processBatch(batches[0])
		}
		setIsLoading(false)
	}

	const handleAddressSelect = useCallback(
		async address => {
			const locationObj = {
				coords: null,
				formattedAddress: address.formattedAddress,
				longAddress: address.longAddress,
				label: address.label,
				instructions: address.instructions,
				updatedAt: address.updatedAt,
				id: address.id,
				addressObject: address.addressObject,
			}
			dispatch(setUserState({ userLocation: locationObj }))
			setShowInvalidAddress(false)
			await handleDeliveryEstimate(address.longAddress)
		},
		[dispatch, handleDeliveryEstimate]
	)

	return (
		<Modal
			visible={modalVisible}
			statusBarTranslucent={true}
			transparent={true}
			animationType="fade"
		>
			<TouchableWithoutFeedback onPress={null}>
				<View style={styles.modalOverlay}>
					<TouchableWithoutFeedback onPress={e => e.stopPropagation()}>
						<View style={styles.modalContent}>
							<ScrollView showsVerticalScrollIndicator={false}>
								{showInvalidAddress && (
									<Banner text="Invalid Address! Please use a different address to continue." />
								)}
								<OrganizationLogoAddressPage imageUri={orgLogoDark || organizationLogo} />
								<DeliveryPickupToggle
									showChange={false}
									onPressPickup={() => {
										dispatch(setPersistedState({ isDelivery: false }))
										setModalVisible(false)
									}}
								/>

								{deliveryAddresses.length > 0 && (
									<>
										<View style={formStyle.headerContainer}>
											<TouchableOpacity
												onPress={() => {
													setShowNewAddressForm(!showNewAddressForm)
												}}
												style={formStyle.touchable}
											>
												<Text style={formStyle.headerText}>Select a delivery address</Text>
											</TouchableOpacity>
										</View>

										{!showNewAddressForm &&
											showSavedAddresses &&
											deliveryAddresses.map((address, index) => (
												<AddressCard
													key={index}
													longAddress={address?.longAddress}
													label={address?.label}
													instructions={address?.instructions}
													onPress={() => handleAddressSelect(address)}
													onDelete={() => {
														alertResponse(
															'Delete saved address?',
															'',
															'Delete',
															'Cancel',
															'default',
															'cancel',
															() => {
																const setDeliveryAddressForUser = httpsCallable(
																	functions,
																	'setDeliveryAddressForUser'
																)

																setDeliveryAddressForUser({
																	userId: userId,
																	address: address,
																	orgId: global.org,
																	isEdit: false,
																	isDelete: true,
																})
															},
															null
														)
													}}
												/>
											))}
									</>
								)}

								<View style={formStyle.headerContainer}>
									<TouchableOpacity
										onPress={() => {
											if (showSavedAddresses) {
												setShowNewAddressForm(!showNewAddressForm)
											}
										}}
										style={formStyle.touchable}
									>
										<Text style={formStyle.headerText}>Enter a new delivery address</Text>
									</TouchableOpacity>
								</View>

								{showNewAddressForm && (
									<AddressForm
										userCurrentLocation={userCurrentLocation}
										handleDeliveryEstimate={handleDeliveryEstimate}
										saveAddress={saveAddress}
										setSaveAddress={setSaveAddress}
										isLoading={isLoading}
										setIsLoading={setIsLoading}
										userId={userId}
									/>
								)}

								<TouchableOpacity
									onPress={() =>
										dispatch(setPersistedState({ contactlessDropoff: !contactlessDropoff }))
									}
									style={[styles.dropoffToggle, { marginLeft: showNewAddressForm ? '2.5%' : 0 }]}
								>
									<MaterialCommunityIcons
										name={
											contactlessDropoff
												? 'checkbox-marked-circle'
												: 'checkbox-blank-circle-outline'
										}
										size={26}
										color={contactlessDropoff ? global.orgColor : '#818181'}
									/>
									<Text style={styles.dropoffToggleText}>Contactless dropoff?</Text>
								</TouchableOpacity>
							</ScrollView>

							{!showNewAddressForm && deliveryAddresses.length > 0 && (
								<TouchableOpacity
									style={[
										formStyle.button,
										{
											backgroundColor: !deviceHasInternet ? Colors.greyscale[6] : global.orgColor,
											width: '100%',
											marginTop: 20,
											marginBottom: 20,
										},
									]}
									disabled={!deviceHasInternet || isLoading}
									onPress={() => {
										if (
											!isLoading &&
											((deliveryEstimate &&
												deliveryEstimate.estimate &&
												deliveryEstimate.estimate.totalFee === -1) ||
												(userLocation && userLocation.error) ||
												!userLocation)
										) {
											setShowInvalidAddress(true)
										} else {
											handleDeliveryEstimate(userLocation.longAddress)
											setModalVisible(false)
										}
									}}
								>
									{isLoading ? (
										<Image
											style={styles.loadingImage}
											source={require('../../assets/images/loadImg.gif')}
										/>
									) : (
										<Text style={formStyle.btnText}>CONTINUE</Text>
									)}
								</TouchableOpacity>
							)}
						</View>
					</TouchableWithoutFeedback>
				</View>
			</TouchableWithoutFeedback>
		</Modal>
	)
}

const styles = StyleSheet.create({
	modalOverlay: {
		flex: 1,
		justifyContent: 'center',
		alignItems: 'center',
		backgroundColor: 'rgba(0,0,0,0.5)',
		paddingHorizontal: '2.5%',
	},
	modalContent: {
		backgroundColor: 'white',
		paddingHorizontal: 20,
		width: '95%',
		maxWidth: 600,
		borderRadius: 10,
		shadowOpacity: 0.25,
		shadowRadius: 3.84,
		elevation: 5,
		maxHeight: '90%',
	},
	loadingImage: {
		width: 90,
		height: 90,
		marginTop: -34,
		marginBottom: -34,
	},
	dropoffToggle: {
		flexDirection: 'row',
		alignItems: 'center',
		marginHorizontal: 0,
		marginVertical: 10,
		paddingBottom: 20,
	},
	dropoffToggleText: {
		flex: 1,
		fontSize: smallTextSize,
		fontWeight: '700',
		marginLeft: 10,
	},
	input: {
		marginVertical: 5,
	},
})
