import { createSlice, PayloadAction } from '@reduxjs/toolkit'

const initialState = {
	userId: '',
	stripeId: '',
	referralCode: '',
	paymentMethodId: '',
	firstName: '',
	lastName: '',
	userEmail: '',
	userPhone: '',
	userData: { user: {} as any },
	deviceHasInternet: true,
	paymentMethods: [] as any[],
	userLocation: {} as any,
}

type StateTypes = typeof initialState

const slice = createSlice({
	name: 'user',
	initialState,
	reducers: {
		setStates: (state, action: PayloadAction<Partial<StateTypes>>) => {
			Object.assign(state, action.payload)
		},
		resetState: state => {
			Object.assign(state, initialState)
		},
	},
})

const { setStates, resetState } = slice.actions

export const setUserState = (updates: Partial<StateTypes>) => setStates(updates)
export const resetUserState = () => resetState()

export default slice.reducer
