import { utcToZonedTime } from 'date-fns-tz'
import { checkSnooze } from './checkSnooze'
import { convertHours } from './prepTimeFunctions'

const DAYS = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']

type ConvertedHours = {
	[key: number]: {
		startTimes: number[]
		endTimes: number[]
	}
}

const formatTime = (decimal: number): string => {
	let hours = Math.floor(decimal)
	let minutes = Math.round((decimal - hours) * 60)
	if (minutes === 60) {
		minutes = 0
		hours += 1
	}
	const period = hours >= 12 ? 'PM' : 'AM'
	const formattedHours = hours % 12 || 12
	return `${formattedHours}:${minutes.toString().padStart(2, '0')} ${period}`
}

const is24_7 = (hours: ConvertedHours | null | undefined): boolean => {
	if (!hours || Object.keys(hours).length === 0) return true

	return Object.values(hours).every(dayHours => {
		if (!dayHours || Object.keys(dayHours).length === 0) return true

		return (
			dayHours.startTimes &&
			dayHours.endTimes &&
			dayHours.startTimes.length === 1 &&
			dayHours.endTimes.length === 1 &&
			dayHours.startTimes[0] === 0 &&
			dayHours.endTimes[0] === 24
		)
	})
}

const getOrgRestrainedHours = (
	restaurantHours: any,
	orgHours: any,
	allowOrdersOutsideOrgHours: boolean
): ConvertedHours | null => {
	if (is24_7(restaurantHours) && (!orgHours || allowOrdersOutsideOrgHours)) {
		return null
	}

	const orgRestrainedHours: ConvertedHours = {}
	for (let i = 0; i < 7; i++) {
		const restDayHours =
			restaurantHours[i] && restaurantHours[i].startTimes
				? restaurantHours[i]
				: { startTimes: [0], endTimes: [24] }
		const orgDayHours =
			orgHours && !allowOrdersOutsideOrgHours
				? convertHours(orgHours, 0)[i] || { startTimes: [0], endTimes: [24] }
				: { startTimes: [0], endTimes: [24] }

		orgRestrainedHours[i] = { startTimes: [], endTimes: [] }

		for (let j = 0; j < restDayHours.startTimes.length; j++) {
			if (restDayHours.startTimes[j] === 0 && restDayHours.endTimes[j] === 0) continue // Closed all day
			if (restDayHours.startTimes[j] === 0 && restDayHours.endTimes[j] === 24) {
				orgRestrainedHours[i] = orgDayHours
				break
			}

			for (let k = 0; k < orgDayHours.startTimes.length; k++) {
				if (orgDayHours.startTimes[k] === 0 && orgDayHours.endTimes[k] === 0) continue
				if (orgDayHours.startTimes[k] === 0 && orgDayHours.endTimes[k] === 24) {
					orgRestrainedHours[i].startTimes.push(restDayHours.startTimes[j])
					orgRestrainedHours[i].endTimes.push(restDayHours.endTimes[j])
					continue
				}

				const start = Math.max(restDayHours.startTimes[j], orgDayHours.startTimes[k])
				const end = Math.min(restDayHours.endTimes[j], orgDayHours.endTimes[k])
				if (start < end) {
					orgRestrainedHours[i].startTimes.push(start)
					orgRestrainedHours[i].endTimes.push(end)
				}
			}
		}
	}

	return orgRestrainedHours
}

export const isRestaurantOpen = (
	restaurant: any,
	orgHours: any,
	allowOrdersOutsideOrgHours: boolean,
	localTimezone: string,
	currTime: any = null
): boolean => {
	const { hours, prepTime = 0, isSnoozed, snoozeUntil } = restaurant

	if (checkSnooze(isSnoozed, snoozeUntil)) {
		return false
	}

	const orgRestrainedHours = getOrgRestrainedHours(hours, orgHours, allowOrdersOutsideOrgHours)

	if (orgRestrainedHours === null) return true // 24/7 open

	const currentTime = currTime !== null ? currTime : utcToZonedTime(new Date(), localTimezone)

	const day = currentTime.getDay()
	const currentDecimalTime = currentTime.getHours() + currentTime.getMinutes() / 60
	const orgRestrainedTime = currentDecimalTime + prepTime / 60

	const todayHours = orgRestrainedHours[day] || { startTimes: [], endTimes: [] }

	return todayHours.startTimes.some(
		(start, index) => orgRestrainedTime >= start && orgRestrainedTime < todayHours.endTimes[index]
	)
}

export const getHoursStatus = (
	restaurant: any,
	orgHours: any,
	allowOrdersOutsideOrgHours: boolean,
	localTimezone: string
): string => {
	const { hours, isSnoozed, snoozeUntil } = restaurant

	if (checkSnooze(isSnoozed, snoozeUntil)) {
		return 'Not accepting new orders right now.'
	}
	const orgRestrainedHours = getOrgRestrainedHours(hours, orgHours, allowOrdersOutsideOrgHours)

	if (orgRestrainedHours === null) return 'Open 24/7'

	const currentTime = utcToZonedTime(new Date(), localTimezone)

	const isOpen = isRestaurantOpen(
		restaurant,
		orgHours,
		allowOrdersOutsideOrgHours,
		localTimezone,
		currentTime
	)

	if (isOpen) {
		const day = currentTime.getDay()
		const currentDecimalTime = currentTime.getHours() + currentTime.getMinutes() / 60
		const todayHours = orgRestrainedHours[day] || { startTimes: [], endTimes: [] }
		const closingTimeIndex = todayHours.endTimes.findIndex(end => currentDecimalTime < end)

		if (closingTimeIndex !== -1) {
			return `Open now until ${formatTime(todayHours.endTimes[closingTimeIndex])}`
		}
	}

	// Find next opening time
	for (let i = 0; i < 7; i++) {
		const nextDay = (currentTime.getDay() + i) % 7
		const nextDayHours = orgRestrainedHours[nextDay] || { startTimes: [], endTimes: [] }

		if (nextDayHours.startTimes.length > 0) {
			const nextOpeningTime = nextDayHours.startTimes[0]

			// If it's today, ensure the opening time is in the future
			if (i === 0) {
				const currentHour = currentTime.getHours() + currentTime.getMinutes() / 60
				if (nextOpeningTime <= currentHour) {
					// Skip today as its opening time has passed
					continue
				}
			}

			const dayDescription = i === 0 ? 'today' : i === 1 ? 'tomorrow' : DAYS[nextDay]
			return `Currently closed. Opens ${dayDescription} at ${formatTime(nextOpeningTime)}`
		}
	}

	return 'Currently closed.'
}

export const areAllRestaurantsInCartOpen = (
	cart: any[],
	restaurants: any[],
	orgHours: any,
	allowOrdersOutsideOrgHours: boolean,
	localTimezone
): boolean => {
	return cart.every(cartItem => {
		const matchingRestaurant = restaurants.find(restaurant => restaurant.id === cartItem.rId)
		if (!matchingRestaurant) {
			return false
		}
		return isRestaurantOpen(matchingRestaurant, orgHours, allowOrdersOutsideOrgHours, localTimezone)
	})
}
