import React, { useState, useRef, useEffect } from 'react'
import { View, TouchableOpacity, Animated, StyleSheet } from 'react-native'
import { mediumTextSize, sTextSize, smallTextSize } from '../../constants/GlobalStyle'
import Colors from '../../constants/Colors'
import NewAddressModal from './NewAddressModal'
import Formatter from '../../helpers/Formatter'
import { Text } from '../Themed'
import { useAppDispatch, useAppSelector } from '../../state/hooks'
import { setPersistedState } from '../../state/Slices/persistedSlice'

const DeliveryPickupToggle = ({
	textColor = null,
	showChange = true,
	showAddress = true,
	onPressPickup = null,
	homePage = false,
}) => {
	const { deliveryEnabled } = useAppSelector(state => state.settings)
	const { userLocation } = useAppSelector(state => state.user)
	const { isDelivery, deliveryEstimate, cartPrep } = useAppSelector(state => state.persisted)

	const [showDeliveryAddressInput, setShowDeliveryAddressInput] = useState(userLocation === null)
	const [deliveryTime, setDeliveryTime] = useState(null)
	const [deliveryFee, setDeliveryFee] = useState(null)
	const [deliveryText, setDeliveryText] = useState('')
	const animation = useRef(new Animated.Value(isDelivery ? 0 : 1)).current // 0 for Delivery, 1 for Pickup

	const dispatch = useAppDispatch()
	useEffect(() => {
		Animated.spring(animation, {
			toValue: isDelivery ? 0 : 1,
			friction: 50,
			tension: 75,
			useNativeDriver: true,
		}).start()
	}, [isDelivery])

	const toggle = value => {
		const isDeliveryValue = value === 'Delivery' && deliveryEnabled ? true : false
		const newCartPrep = { ...cartPrep }
		if (
			(cartPrep.isScheduled && isDeliveryValue && cartPrep.deliveryTime === null) ||
			(cartPrep.isScheduled && !isDeliveryValue && cartPrep.pickupTime === null)
		) {
			newCartPrep.isScheduled = false
		}
		if (isDeliveryValue) {
			const deliveryTime =
				deliveryEstimate && deliveryEstimate.estimate ? deliveryEstimate.estimate.timeToDeliver : 60

			newCartPrep.deliveryPrepTime = deliveryTime
		}

		dispatch(
			setPersistedState({
				isDelivery: isDeliveryValue,
				cartPrep: newCartPrep,
			})
		)
		setShowDeliveryAddressInput(true)
		if (value === 'Pickup' && onPressPickup) {
			onPressPickup()
		}
	}

	useEffect(() => {
		if (
			isDelivery &&
			deliveryEstimate &&
			deliveryEstimate.estimate &&
			deliveryEstimate.estimate.fee === -1
		) {
			setShowDeliveryAddressInput(true)
		}
	}, [isDelivery, deliveryEstimate])

	useEffect(() => {
		if (deliveryEstimate && deliveryEstimate.restaurantId && deliveryEstimate.estimate) {
			if (
				deliveryEstimate.estimate.totalFee !== -1 &&
				deliveryEstimate.estimate.timeToDeliver !== -1
			) {
				const fee = deliveryEstimate.estimate.fee <= 0 ? 0 : deliveryEstimate.estimate.fee / 100
				setDeliveryTime(deliveryEstimate.estimate.timeToDeliver)
				setDeliveryFee(deliveryEstimate.estimate.fee)
				let minTime = Math.round((deliveryEstimate.estimate.timeToDeliver * 0.7) / 5) * 5 + 5 //round to nearest 5 minutes
				const maxTime = deliveryEstimate.estimate.timeToDeliver + 5
				if (minTime === maxTime) {
					minTime -= 5
				}
				setDeliveryText(`${minTime}-${maxTime} min. ${Formatter.currencyFormat(fee)}`)
			} else {
				setDeliveryText('Cannot deliver to this address.')
			}
		}
	}, [deliveryEstimate])

	const slidingStyle = {
		transform: [
			{
				translateX: animation.interpolate({
					inputRange: [0, 1],
					outputRange: [2, 68],
				}),
			},
		],
	}
	if (!deliveryEnabled) return null

	return (
		<>
			<View
				style={{
					flexDirection: homePage ? 'row-reverse' : 'row',
					justifyContent: 'space-between',
					marginTop: showAddress ? 10 : 0,
				}}
			>
				<View style={styles.container}>
					<Animated.View style={[styles.oval, slidingStyle]} />
					<TouchableOpacity
						style={styles.button}
						activeOpacity={0.8}
						onPress={() => toggle('Delivery')}
					>
						<Text style={[styles.text, { marginLeft: 2 }]}>Delivery</Text>
					</TouchableOpacity>
					<TouchableOpacity
						style={styles.button}
						activeOpacity={0.8}
						onPress={() => toggle('Pickup')}
					>
						<Text style={styles.text}>Pickup</Text>
					</TouchableOpacity>
				</View>
				<View style={{ display: isDelivery && showAddress ? 'flex' : 'none', flex: 1 }}>
					<View style={styles.outerContainer}>
						<View style={styles.addressContainer}>
							<TouchableOpacity
								onPress={() => setShowDeliveryAddressInput(true)}
								style={{ flexDirection: 'row', alignItems: 'center' }}
							>
								<Text
									style={[
										styles.addressText,
										textColor !== null ? { color: textColor } : { color: global.orgColor2 },
									]}
									numberOfLines={1}
									ellipsizeMode="tail"
								>
									{userLocation && userLocation.label
										? 'To: ' + userLocation.label
										: userLocation && userLocation.formattedAddress
											? 'To: ' + userLocation.formattedAddress
											: ''}
								</Text>
								{userLocation && userLocation.formattedAddress && showChange && (
									<Text
										style={{
											color: homePage ? textColor : 'black',
											textDecorationLine: 'underline',
											fontSize: sTextSize,
											marginLeft: 4,
											marginRight: homePage ? 10 : 0,
										}}
									>
										Change
									</Text>
								)}
							</TouchableOpacity>
						</View>
					</View>
					<View
						style={[
							styles.estimateContainer,
							{
								display: userLocation && userLocation.formattedAddress ? 'flex' : 'none',
							},
						]}
					>
						<Text style={[styles.estimateText, { color: global.orgColor2 }]}>{deliveryText}</Text>
					</View>
				</View>
			</View>
			{showChange && (
				<NewAddressModal
					modalVisible={showDeliveryAddressInput}
					setModalVisible={setShowDeliveryAddressInput}
				/>
			)}
		</>
	)
}

const styles = StyleSheet.create({
	container: {
		flexDirection: 'row',
		width: 140,
		height: 37,
		backgroundColor: Colors.greyscale[4],
		borderRadius: 20,
		position: 'relative',
		opacity: 0.85,
		alignSelf: 'flex-end',
	},
	estimateContainer: {
		position: 'relative',
		width: '100%',
		alignSelf: 'flex-end',
		//marginTop: 3,
	},
	estimateText: {
		textAlign: 'right',
		fontSize: smallTextSize,
		color: Colors.greyscale[6],
		flex: 1, // Allows text to take up available space without pushing "Change"
	},
	changeContainer: {
		position: 'relative',
		alignSelf: 'flex-end',
	},
	addressContainer: {
		position: 'relative',
		width: '100%',
		alignSelf: 'flex-end',
	},
	outerContainer: {
		marginLeft: 30,
		flexDirection: 'row',
		justifyContent: 'space-between',
	},
	addressText: {
		textAlign: 'right',
		fontSize: smallTextSize,
		color: Colors.greyscale[6],
		flex: 1, // Allows text to take up available space without pushing "Change"
	},

	oval: {
		top: 1.5,
		bottom: 1.5,
		width: 70,
		backgroundColor: 'white',
		borderRadius: (mediumTextSize * 2.5) / 2,
		position: 'absolute',
	},
	button: {
		flex: 1,
		justifyContent: 'center',
		alignItems: 'center',
	},
	text: {
		fontSize: 15,
		fontWeight: '500',
	},
})

export default DeliveryPickupToggle
