import React, { useState } from 'react'
import {
	View,
	TextInput,
	StyleSheet,
	Keyboard,
	TouchableWithoutFeedback,
	Platform,
} from 'react-native'
import { Text } from '../../../../components/Themed'
import { FontAwesome, Ionicons, MaterialCommunityIcons } from '@expo/vector-icons'
import { CheckBox } from 'react-native-elements'
import NumberPad from '../../../../components/NumberPad'
import { largeTextSize, titleTextSize } from '../../../../constants/GlobalStyle'
import { auth } from '../../../../firebase/config'
import Formatter from '../../../../helpers/Formatter'
import Colors from '../../../../constants/Colors'
import { useAppSelector, useAppDispatch } from '../../../../state/hooks'
import { setCheckoutState } from '../../../../state/Slices/checkoutSlice'

/**
 * Screen to choose the desired receipt options & text updates
 * Email (through sendgrid), text (through twilio), or print (alerts R.M. to print receipt with order)
 */
const Receipt = ({ children }) => {
	const dispatch = useAppDispatch()
	const { selectedMethods, savedPhone } = useAppSelector(state => state.checkout)
	const { isDelivery } = useAppSelector(state => state.persisted)
	const [keyboardActive, setKeyboardActive] = useState(false)

	const isKiosk = global.kiosk
	const isWeb = Platform.OS === 'web'
	const isLoggedIn = auth.currentUser !== null && !global.kiosk

	const descriptor = global.pos ? 'THEIR' : 'YOUR'
	const pronoun = global.pos ? 'THEY' : 'YOU'

	const formatPhoneNumber = inputString => {
		const numbers = inputString.replace(/\D/g, '')
		const char = { 0: '(', 3: ')-', 6: '-' }
		let phone = ''
		for (let i = 0; i < numbers.length; i++) {
			phone += (char[i] || '') + numbers[i]
		}
		return phone
	}

	const handleMethodChange = (method, value) => {
		dispatch(setCheckoutState({ selectedMethods: { ...selectedMethods, [method]: value } }))
	}

	const renderTextMessageOption = () => (
		<>
			<CheckBox
				title={isLoggedIn ? `Text message to ${Formatter.phoneNumber(savedPhone)}` : 'Text Message'}
				checked={selectedMethods.textMessage}
				checkedColor={global.orgColor}
				checkedIcon={
					<MaterialCommunityIcons
						name="checkbox-marked"
						size={titleTextSize}
						color={global.orgColor}
					/>
				}
				uncheckedIcon={
					<MaterialCommunityIcons
						name="checkbox-blank-outline"
						size={titleTextSize}
						color="black"
					/>
				}
				onPress={() => {
					handleMethodChange('textMessage', !selectedMethods.textMessage)
					if (isKiosk) {
						handleMethodChange('showNumPad', !selectedMethods.textMessage)
					}
				}}
				containerStyle={styles.checkboxContainer}
				textStyle={styles.checkboxText}
			/>
			{selectedMethods.textMessage && !isLoggedIn && (
				<View style={styles.inputContainer}>
					<FontAwesome
						name="mobile-phone"
						size={titleTextSize * 1.15}
						color={Colors.greyscale[3]}
						style={styles.inputIcon}
					/>
					<TextInput
						style={styles.input}
						placeholder="Mobile number"
						placeholderTextColor="#aaa"
						value={selectedMethods.phoneStringFormatted}
						maxLength={14}
						autoFocus={true}
						showSoftInputOnFocus={!isKiosk}
						editable={!isKiosk}
						selectTextOnFocus={!isKiosk}
						onFocus={() => {
							if (isKiosk) {
								handleMethodChange('showNumPad', true)
							} else {
								setKeyboardActive(true)
							}
						}}
						onBlur={() => {
							if (isKiosk) {
								handleMethodChange('showNumPad', false)
								handleMethodChange('textMessage', selectedMethods.phoneString !== '')
							} else {
								setKeyboardActive(false)
							}
						}}
						onChangeText={value => {
							const formattedValue = formatPhoneNumber(value)
							dispatch(
								setCheckoutState({
									selectedMethods: {
										...selectedMethods,
										phoneString: value,
										phoneStringFormatted: formattedValue,
									},
								})
							)
						}}
						returnKeyType={'done'}
						keyboardType="numeric"
					/>
				</View>
			)}
			{selectedMethods.textMessage && (isKiosk || !isDelivery) && (
				<CheckBox
					title={isDelivery ? 'Receive delivery updates by text' : 'Also receive order updates'}
					checked={selectedMethods.textUpdates}
					checkedColor={global.orgColor}
					checkedIcon={
						<MaterialCommunityIcons
							name="checkbox-marked"
							size={titleTextSize}
							color={global.orgColor}
						/>
					}
					uncheckedIcon={
						<MaterialCommunityIcons
							name="checkbox-blank-outline"
							size={titleTextSize}
							color="black"
						/>
					}
					onPress={() => handleMethodChange('textUpdates', !selectedMethods.textUpdates)}
					containerStyle={[styles.checkboxContainer, { marginTop: 5, marginLeft: '14%' }]}
					textStyle={styles.checkboxText}
				/>
			)}
			{isKiosk && selectedMethods.showNumPad && (
				<NumberPad
					style={{
						marginBottom: 0,
						width: '80%',
					}}
					numLength={12}
					buttonSize={60}
					buttonItemStyle={{ backgroundColor: '#d6d6d6', borderRadius: 12, width: '80%' }}
					activeOpacity={0.1}
					onValueChange={value => {
						dispatch(
							setCheckoutState({
								selectedMethods: {
									...selectedMethods,
									phoneString: value,
									phoneStringFormatted: formatPhoneNumber(value),
								},
							})
						)
					}}
					allowDecimal={false}
					rightBottomButton={<Ionicons name={'ios-backspace-outline'} size={28} color={'#000'} />}
				/>
			)}
		</>
	)

	const renderEmailOption = () => (
		<>
			<CheckBox
				title={isLoggedIn ? `Email ${Formatter.decodedEmail(auth.currentUser.email)}` : 'Email'}
				checked={selectedMethods.email}
				checkedColor={global.orgColor}
				checkedIcon={
					<MaterialCommunityIcons
						name="checkbox-marked"
						size={titleTextSize}
						color={global.orgColor}
					/>
				}
				uncheckedIcon={
					<MaterialCommunityIcons
						name="checkbox-blank-outline"
						size={titleTextSize}
						color="black"
					/>
				}
				onPress={() => handleMethodChange('email', !selectedMethods.email)}
				containerStyle={[styles.checkboxContainer, { marginBottom: isDelivery ? 5 : 30 }]}
				textStyle={styles.checkboxText}
			/>
			{selectedMethods.email && !isLoggedIn && (
				<View style={[styles.inputContainer, { marginBottom: 30 }]}>
					<FontAwesome
						name="envelope"
						size={largeTextSize * 1.05}
						color={Colors.greyscale[3]}
						style={styles.inputIcon}
					/>
					<TextInput
						style={[styles.input]}
						autoFocus={true}
						onFocus={() => setKeyboardActive(true)}
						onBlur={() => {
							setKeyboardActive(false)
							if (selectedMethods.emailString === '') {
								handleMethodChange('email', false)
							}
						}}
						placeholder="Email address"
						placeholderTextColor="#aaa"
						value={selectedMethods.emailString}
						onChangeText={value =>
							dispatch(
								setCheckoutState({
									selectedMethods: { ...selectedMethods, emailString: value },
								})
							)
						}
						returnKeyType={'done'}
						keyboardType="email-address"
					/>
				</View>
			)}
		</>
	)

	const renderPrintOption = () => (
		<>
			<CheckBox
				title="Print"
				checked={selectedMethods.print}
				checkedColor={global.orgColor}
				checkedIcon={
					<MaterialCommunityIcons
						name="checkbox-marked"
						size={titleTextSize}
						color={global.orgColor}
					/>
				}
				uncheckedIcon={
					<MaterialCommunityIcons
						name="checkbox-blank-outline"
						size={titleTextSize}
						color="black"
					/>
				}
				onPress={() => handleMethodChange('print', !selectedMethods.print)}
				containerStyle={styles.checkboxContainer}
				textStyle={styles.checkboxText}
			/>
			{selectedMethods.print && !keyboardActive && (
				<View style={{ width: '75%' }}>
					<Text style={[styles.printNote, { fontWeight: 'bold' }]}>
						{'NOTE: '}
						<Text style={[styles.printNote]}>
							{`${pronoun.toLowerCase()} will receive ${descriptor.toLowerCase()} printed receipt along with ${descriptor.toLowerCase()} order.`}
						</Text>
					</Text>
				</View>
			)}
		</>
	)

	return (
		<>
			<TouchableWithoutFeedback onPress={isKiosk ? Keyboard.dismiss : null}>
				<View style={styles.container}>
					<Text style={styles.subHeader}>{`HOW WOULD ${pronoun} LIKE ${descriptor} RECEIPT?`}</Text>
					<Text style={[styles.subHeader, { color: '#818181', marginBottom: 30, marginTop: 15 }]}>
						{'(SELECT ONE OR MORE)'}
					</Text>
					{renderTextMessageOption()}
					{!selectedMethods.showNumPad && (
						<>
							{renderEmailOption()}
							{isKiosk && renderPrintOption()}
							{isDelivery && !isKiosk && (
								<CheckBox
									title="Receive delivery updates by text"
									checked={selectedMethods.textUpdates}
									checkedColor={global.orgColor}
									checkedIcon={
										<MaterialCommunityIcons
											name="checkbox-marked"
											size={titleTextSize}
											color={global.orgColor}
										/>
									}
									uncheckedIcon={
										<MaterialCommunityIcons
											name="checkbox-blank-outline"
											size={titleTextSize}
											color="black"
										/>
									}
									onPress={() => handleMethodChange('textUpdates', !selectedMethods.textUpdates)}
									containerStyle={styles.checkboxContainer}
									textStyle={styles.checkboxText}
								/>
							)}
						</>
					)}
				</View>
			</TouchableWithoutFeedback>
			{children}
		</>
	)
}

const styles = StyleSheet.create({
	container: {
		flex: 1,
		alignItems: 'center',
		paddingTop: 20,
		marginTop: '5%',
		backgroundColor: '#fff',
		borderRadius: 25,
	},

	mainHeader: {
		fontSize: titleTextSize,
		textAlign: 'center',
		marginBottom: 50,
		fontWeight: '600',
	},
	subHeader: {
		fontSize: largeTextSize,
		color: Colors.greyscale[8],
		textAlign: 'center',
		fontWeight: '600',
	},
	inputContainer: {
		flexDirection: 'row',
		borderColor: 'grey',
		borderWidth: 1,
		borderRadius: 8,
		paddingHorizontal: 10,
		paddingVertical: 8,
		alignItems: 'center',
		width: '75%',
	},
	inputIcon: {
		marginRight: 10,
	},
	input: {
		flex: 1,
		paddingVertical: 10,
		fontSize: largeTextSize,
	},
	checkboxContainer: {
		backgroundColor: 'transparent',
		borderWidth: 0,
		paddingVertical: 5,
		marginLeft: '11%',
		marginRight: '11%',
		alignSelf: 'flex-start',
		marginTop: 30,
	},
	checkboxText: {
		marginHorizontal: 5,
		fontWeight: '500',
		fontSize: largeTextSize,
	},
	printNote: {
		paddingVertical: 5,
		alignSelf: 'flex-start',
		fontWeight: '300',
		fontSize: largeTextSize,
		color: '#4E4E4E',
	},
})

export default Receipt
