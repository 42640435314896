import { doc, collection, onSnapshot } from 'firebase/firestore'
import { db } from '../../../firebase/config'
import '../../../global'
import { useEffect, useState } from 'react'
import _ from 'lodash'
import { formatModifierLists } from '../getModifierListData'
import { DATABASE_NAME } from '@env'
import { useAppDispatch, useAppSelector } from '../../../state/hooks'
import { setDataState } from '../../../state/Slices/dataSlice'

const useModListListener = () => {
	const { loadedData } = useAppSelector(state => state.data)

	const [modListData, setModListData] = useState({})
	const dispatch = useAppDispatch()

	useEffect(() => {
		const modListRef = collection(doc(db, DATABASE_NAME, global.org), 'ModifierLists')
		const unsubscribe = onSnapshot(modListRef, snapshot => {
			const newModListData = { ...modListData }
			let updated = false

			snapshot.docChanges().forEach(change => {
				const data = change.doc.data()
				if (change.type === 'modified' && data !== undefined && !data.isDeleted) {
					const modListReturn = formatModifierLists(data)
					newModListData[data.id] = modListReturn
					updated = true
				}
			})

			if (updated) {
				const updatedData = _.merge({}, loadedData, newModListData)
				setModListData(newModListData)
				dispatch(
					setDataState({
						loadedData: updatedData,
					})
				)
				// if (Object.keys(newModListData).length > 0) {
				// 	//setUpdateData([])
				// }
			}
		})

		return () => {
			unsubscribe()
		}
	}, [loadedData, modListData, dispatch])

	return null
}

export default useModListListener
