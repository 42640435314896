import { useEffect } from 'react'
import { calculateBagFee } from '../Helpers/priceFunctions'
import { auth } from '../../../firebase/config'
import stripeGetCards from '../../../components/Stripe/StripeGetCards'
import { useSquare } from '../../../components/Square/Hooks/useSquare'
import { useAppDispatch, useAppSelector } from '../../../state/hooks'
import { resetUserState, setUserState } from '../../../state/Slices/userSlice'
import { setCheckoutState } from '../../../state/Slices/checkoutSlice'

const useFetchCards = () => {
	const dispatch = useAppDispatch()
	const userId = auth.currentUser ? auth.currentUser.uid : ''
	const { getCards } = useSquare()
	const { loadedData } = useAppSelector(state => state.data)
	const { paymentMethodId, paymentMethods, userData } = useAppSelector(state => state.user)
	const { isUser } = useAppSelector(state => state.checkout)
	const { paymentProvider } = useAppSelector(state => state.settings)
	useEffect(() => {
		const fetchData = async () => {
			dispatch(setCheckoutState({ loading: true }))

			dispatch(
				setCheckoutState({
					bagFeeEnabled:
						loadedData[global.org].bagFee && loadedData[global.org].bagFee.enabled ? true : false,
				})
			)

			if (loadedData[global.org].bagFee && loadedData[global.org].bagFee.enabled) {
				dispatch(
					setCheckoutState({
						bagFee: calculateBagFee(loadedData[global.org].bagFee),
					})
				)
			}

			if (userId !== '' && userData.user && userData.user.phone && auth.currentUser) {
				dispatch(
					setCheckoutState({
						savedPhone: userData.user.phone || '',
					})
				)

				if (paymentProvider === 'stripe') {
					try {
						if (userData && userData.user && userData.user.stripeId) {
							const stripeCards = await stripeGetCards(userData.user.stripeId)

							dispatch(
								setUserState({
									stripeId: userData.user.stripeId,
									paymentMethods: stripeCards,
								})
							)

							if (stripeCards.length > 0 && paymentMethodId === '') {
								dispatch(
									setUserState({
										paymentMethodId: stripeCards[0].id,
									})
								)
							}
						} else {
							dispatch(setUserState({ paymentMethodId: '', paymentMethods: [] }))
						}

						dispatch(setCheckoutState({ loading: false }))
					} catch (error) {
						dispatch(setCheckoutState({ loading: false }))
						console.error('Error fetching Stripe cards:', error)
					}
				}

				if (!global.kiosk && paymentProvider === 'square') {
					loadSquareCards()
				}
			} else {
				dispatch(setUserState({ paymentMethodId: '', paymentMethods: [] }))
				dispatch(setCheckoutState({ loading: false }))
			}
		}

		fetchData()
	}, [userId, userData, isUser, dispatch])

	useEffect(() => {
		if (paymentMethods && paymentMethods.length >= 1) {
			dispatch(setUserState({ paymentMethodId: paymentMethods[0].id }))
		} else {
			dispatch(setUserState({ paymentMethodId: '' }))
		}
	}, [paymentMethods, dispatch])

	const loadSquareCards = async () => {
		dispatch(setCheckoutState({ loading: true }))
		const squareCards = await getCards()
		dispatch(setCheckoutState({ loading: false }))

		dispatch(
			setUserState({
				paymentMethods: squareCards,
			})
		)

		if (squareCards.length > 0 && paymentMethodId === '') {
			dispatch(
				setUserState({
					paymentMethodId: squareCards[0].id,
				})
			)
		}
	}
}

export default useFetchCards
