import React, { useEffect, useMemo, useRef, useState } from 'react'
import {
	View,
	Image,
	FlatList,
	Dimensions,
	Platform,
	TouchableOpacity,
	Animated,
} from 'react-native'
import { Text } from '../../../components/Themed'
import GlobalStyle, { MED_WIDTH, titleTextSize } from '../../../constants/GlobalStyle'
import { MenuHeader } from './Headers'
import CategorySidebar from './CategorySidebar'
import { styles } from '../Helpers/style'
import Colors from '../../../constants/Colors'
import { responsiveHeight } from 'react-native-responsive-dimensions'
import Fuse from 'fuse.js'
import _ from 'lodash'
import NavFooter from '../../../components/Footers/NavFooter'
import { logSelectItemAnalytics } from '../../../firebase/analytics'
import { LocationSelectModal } from '../../../components/LocationSelect'
import TapToLogout from '../../../components/Kiosk/TapToLogout'
import POSNavBar from '../../../navigation/POSNavBar'
import ExitIcon from '../../../components/Headers/UIButtons'
import { WebHeader } from '../../../components/Headers/WebHeader'
import CopyrightFooter from '../../../components/Footers/CopyrightFooter'
import KioskStartScreen from '../../RestaurantSelect/Components/KioskStartScreen'
import DeliveryPickupToggle from '../../../components/Delivery/DeliveryPickupToggle'
import { checkSnooze } from '../../../helpers/checkSnooze'
import {
	Timestamp,
	collection,
	doc,
	limit,
	onSnapshot,
	orderBy,
	query,
	startAfter,
	where,
} from 'firebase/firestore'
import { DATABASE_NAME } from '@env'
import { auth, db } from '../../../firebase/config'
import { useAppDispatch, useAppSelector } from '../../../state/hooks'
import { setPersistedState } from '../../../state/Slices/persistedSlice'
import { setDataState } from '../../../state/Slices/dataSlice'
import { setMenuState } from '../../../state/Slices/menuSlice'
import Search from './Search'
import { addExtraComponents, getBrandCardsLayout } from '../Helpers/functions'
import RowItems from './RowItems'
/**
 * Menu page for a specific brand
 * Differs from category menu in that it shows all items in a brand,
 * Has a search bar, more detailed header, and a category sidebar/top bar
 */
const BrandMenu = ({
	navigation,
	ctgTokens,
	storeOpen,
	heroImageURI,
	logoImageURI,
	rName,
	hoursText,
	rId,
	address,
}) => {
	const { deliveryEnabled } = useAppSelector(state => state.settings)

	const { cart } = useAppSelector(state => state.persisted)

	const { userLocation } = useAppSelector(state => state.user)

	const { loadedData, organizationLogo, showKioskStartScreen, restaurantList } = useAppSelector(
		state => state.data
	)

	const singleBrandEnabled = loadedData[global.org].singleBrandEnabled || false

	const flatListRef = useRef(null)
	const inputRef = useRef(null)
	const stickySidebarRef = useRef(null) // question why same ref to multiple components appear to not work

	const [isSticky, setSticky] = useState(false)
	const [loading, setLoading] = useState(false)
	const [menuItems, setMenuItems] = useState([])
	const [fuse, setFuse] = useState(null)
	const [slideAnim, setSlideAnim] = useState(new Animated.Value(-100))
	const [isBottom, setIsBottom] = useState(false)
	const [isSnoozedState, setIsSnoozedState] = useState(false)

	const { showDropdown, isSearchActive } = useAppSelector(state => state.menu)

	const [locationsModalVisible, setLocationsModalVisible] = useState(
		userLocation !== null ? false : true
	)
	const dispatch = useAppDispatch()

	const card = getBrandCardsLayout(singleBrandEnabled, false)

	const toggleDropdown = () => {
		if (showDropdown) {
			//dropdown is open, close it
			dispatch(setMenuState({ showResults: false }))

			setTimeout(() => {
				if (inputRef.current) {
					inputRef.current.blur()
				}
				dispatch(setMenuState({ showDropdown: false }))
			}, 300)
		} else {
			if (inputRef.current) {
				inputRef.current.focus()
			}
			dispatch(setMenuState({ showDropdown: !showDropdown }))
		}
	}

	const handleScroll = event => {
		const offsetY = event.nativeEvent.contentOffset.y
		const contentHeight = event.nativeEvent.contentSize.height
		const height = event.nativeEvent.layoutMeasurement.height

		const paddingBottom = 200
		if (offsetY + height >= contentHeight - paddingBottom) {
			setIsBottom(true)
		} else {
			setIsBottom(false)
		}

		if (!showDropdown) {
			const scrollY = event.nativeEvent.contentOffset.y
			const stickyThreshold = 200
			if (scrollY >= stickyThreshold && !isSticky) {
				setSticky(true)
			} else if (scrollY < stickyThreshold && isSticky) {
				setSticky(false)
			}
		}
	}

	const [visibleCategory, setVisibleCategory] = useState(null)
	const [visibleCategories, setVisibleCategories] = useState([])

	useEffect(() => {
		if (stickySidebarRef && visibleCategory) {
			const index = buildCategoryList.findIndex(item => item.name === visibleCategory)
			stickySidebarRef.current?.scrollToIndex({
				index,
				animated: true,
				viewOffset: 100,
			})
		}
	}, [visibleCategory])

	const viewabilityConfig = useRef({
		itemVisiblePercentThreshold: 50,
	}).current

	const buildCategoryList = useMemo(() => {
		const categoryList = []

		const items = []

		Object.entries(ctgTokens).forEach(([name, tokens]: any) => {
			categoryList.push({
				name: name,
				items: addExtraComponents(tokens, card.cardsPerRow),
			})
			tokens.forEach(token => {
				if (!token.id.startsWith('invisible_')) {
					items.push({
						...token,
					})
				}
			})
		})
		// Set weighting for search term relation to items
		const options = {
			threshold: 0.45,
			keys: [
				{ name: 'name', weight: 0.45 },
				{ name: 'desc', weight: 0.3 },
				{ name: 'category', weight: 0.25 },
			],
		}

		setFuse(new Fuse(items, options)) // Initialize the fuzzy search
		return categoryList
	}, [ctgTokens, visibleCategory])

	const onViewableItemsChanged = useRef(({ viewableItems }) => {
		setVisibleCategories(viewableItems)
	}).current

	useEffect(() => {
		if (
			isBottom &&
			visibleCategories.some(
				item => item.key === buildCategoryList[buildCategoryList.length - 1].items[0].id
			)
		) {
			setVisibleCategory(visibleCategories[visibleCategories.length - 1].item.name)
		} else if (visibleCategories.length > 0) {
			setVisibleCategory(visibleCategories[0].item.name)
		}
	}, [visibleCategories])

	useEffect(() => {
		if (!global.kiosk) {
			dispatch(setPersistedState({ activeRID: rId }))
		}
		setIsSnoozedState(checkSnooze(loadedData[rId].isSnoozed, loadedData[rId].snoozeUntil))
	}, [loadedData])

	const setShowKioskStartScreen = value => {
		dispatch(setDataState({ showKioskStartScreen: value }))
	}

	return singleBrandEnabled && showKioskStartScreen && !global.pos && global.kiosk ? (
		<KioskStartScreen
			defaultLogo={organizationLogo}
			imageList={loadedData.promoImages ? loadedData.promoImages : []}
			setVisible={setShowKioskStartScreen}
		/>
	) : (
		<>
			{singleBrandEnabled && restaurantList.length > 1 && !global.kiosk && (
				<LocationSelectModal
					visible={locationsModalVisible}
					setVisible={setLocationsModalVisible}
					navigation={navigation}
					rIdToken={rId}
				/>
			)}

			{Platform.OS === 'web' && singleBrandEnabled && (
				<WebHeader
					logo={isSticky && MED_WIDTH ? organizationLogo : null}
					navigation={navigation}
					returnToTop={() => {
						flatListRef.current.scrollToOffset({ offset: 0, animated: true })
					}}
				/>
			)}

			<View style={{ flex: 1 }}>
				<View
					style={
						isSticky && MED_WIDTH
							? {
									position: 'absolute',
									top: 0,
									left: 0,
									right: 0,
									zIndex: 1,
									display: 'flex',
									paddingTop: Platform.OS === 'web' ? 20 : 60,
									backgroundColor: 'white',
								}
							: { display: 'none' }
					}
				>
					<CategorySidebar
						categories={Object.keys(ctgTokens)}
						style={{ flex: 1 }}
						logo={logoImageURI}
						horizontalScroll={true}
						selectedCategory={visibleCategory}
						ref={stickySidebarRef}
						onSelectCategory={category => {
							const index = buildCategoryList.findIndex(item => item.name === category)
							flatListRef.current?.scrollToIndex({
								index,
								animated: true,
								viewOffset: 100,
							})
						}}
					/>
				</View>
				<Search
					ctgTokens={ctgTokens}
					rName={rName}
					rId={rId}
					isSnoozedState={isSnoozedState}
					logSelectItemAnalytics={logSelectItemAnalytics}
					navigation={navigation}
					storeOpen={storeOpen}
				/>
				{!singleBrandEnabled && !showDropdown && (
					<ExitIcon
						onPress={() => {
							if (Platform.OS === 'web') {
								navigation.replace('HomeStack', { screen: 'RestaurantSelect' })
							} else {
								navigation.navigate('HomeStack', { screen: 'RestaurantSelect' })
							}
						}}
					/>
				)}
				{ctgTokens && Object.entries(ctgTokens).length !== 0 ? (
					<FlatList
						data={buildCategoryList}
						initialNumToRender={3}
						ref={flatListRef}
						onViewableItemsChanged={onViewableItemsChanged}
						viewabilityConfig={viewabilityConfig}
						onScroll={handleScroll}
						scrollEventThrottle={16}
						windowSize={41}
						renderItem={({ item }) => (
							<View
								key={item.name + item.items[0].id}
								style={{
									marginRight: '5%',
									marginLeft: MED_WIDTH ? '5%' : 270,
									opacity: isSnoozedState ? 0.65 : 1,
								}}
							>
								{item.name && (
									<Text
										style={[
											GlobalStyle.titleText,
											{ color: global.orgColor2, fontSize: titleTextSize },
										]}
									>
										{item.name.toUpperCase()}
									</Text>
								)}

								<RowItems
									name={item.name}
									rowItems={item.items}
									storeOpen={storeOpen}
									navigation={navigation}
									logSelectItemAnalytics={logSelectItemAnalytics}
									rId={rId}
									rName={rName}
									isSnoozedState={isSnoozedState}
									isBrandMenu={true}
									singleBrandEnabled={singleBrandEnabled}
								/>
							</View>
						)}
						keyExtractor={item => item.items[0].id}
						ListHeaderComponent={
							<>
								<MenuHeader
									HeroImageURI={heroImageURI}
									LogoImageURI={MED_WIDTH ? logoImageURI : null}
									navigation={navigation}
									fill={MED_WIDTH ? 'white' : Colors.custom.appBackground}
									rName={rName}
									hoursText={isSnoozedState ? 'Not taking orders right now.' : hoursText}
									toggle={() => {
										toggleDropdown()
										dispatch(setMenuState({ isSearchActive: true }))
									}}
									sidebarVisible={!MED_WIDTH}
									address={address}
									setLocationPopup={
										singleBrandEnabled && restaurantList.length > 1
											? setLocationsModalVisible
											: singleBrandEnabled && !global.kiosk
												? -1
												: null
									}
									rId={rId}
								/>

								<TouchableOpacity
									style={{ alignSelf: 'flex-end' }}
									onPress={() => {
										toggleDropdown()
										dispatch(setMenuState({ isSearchActive: true }))
									}}
								></TouchableOpacity>

								{!isSticky && MED_WIDTH ? (
									<>
										{!global.kiosk && (
											<View
												style={{
													backgroundColor: 'white',
													paddingHorizontal: '5%',
													paddingTop: 5,
													paddingBottom: 20,
													display: deliveryEnabled ? 'flex' : 'none',
												}}
											>
												<DeliveryPickupToggle />
											</View>
										)}

										<CategorySidebar
											categories={Object.keys(ctgTokens)}
											style={{ flex: 1 }}
											logo={logoImageURI}
											horizontalScroll={true}
											selectedCategory={visibleCategory}
											ref={null}
											onSelectCategory={category => {
												const index = buildCategoryList.findIndex(item => item.name === category)
												flatListRef.current?.scrollToIndex({
													index,
													animated: true,
													viewOffset: 100,
												})
											}}
										/>
									</>
								) : (
									<View style={{ padding: 15 }}></View>
								)}
								{global.kiosk && !global.pos && (
									<Text
										style={[
											styles.subHeaderText,
											{
												marginRight: '5%',
												marginLeft: MED_WIDTH ? '5%' : 270,
												marginTop: MED_WIDTH ? '5%' : 0,
											},
										]}
									>{`WHAT WOULD YOU LIKE TODAY?`}</Text>
								)}
							</>
						}
						ListHeaderComponentStyle={{ marginBottom: 20, zIndex: 10 }}
						style={{
							marginBottom:
								!singleBrandEnabled && !showDropdown ? Dimensions.get('window').height * 0.1 : 0,
						}}
						ListFooterComponent={
							<>{Platform.OS === 'web' && singleBrandEnabled && <CopyrightFooter />}</>
						}
					/>
				) : (
					<View style={{ alignItems: 'center', justifyContent: 'center' }}>
						<Image
							style={{
								width: 100,
								height: 100,
							}}
							source={require('../../../assets/images/loadImg.gif')}
						/>
					</View>
				)}

				{!MED_WIDTH && (
					<>
						<CategorySidebar
							horizontalScroll={false}
							onReturnToTop={() => {
								flatListRef.current.scrollToOffset({ offset: 0, animated: true })
							}}
							categories={Object.keys(ctgTokens)}
							style={{
								flex: 1,
								bottom:
									singleBrandEnabled && cart.length > 0 && Platform.OS !== 'web'
										? Dimensions.get('window').height * 0.1
										: Platform.OS === 'web'
											? 50
											: 0,
							}}
							logo={logoImageURI}
							selectedCategory={visibleCategory}
							ref={stickySidebarRef}
							onSelectCategory={category => {
								const index = buildCategoryList.findIndex(item => item.name === category)
								flatListRef.current?.scrollToIndex({
									index,
									animated: true,
									viewOffset: responsiveHeight(5),
								})
							}}
						/>
					</>
				)}
			</View>
			{global.kiosk && singleBrandEnabled ? (
				<>
					<TapToLogout navigation={navigation} />
					{global.pos ? (
						<POSNavBar navigation={navigation} screenName={'HomeStack'} />
					) : (
						<NavFooter navigation={navigation} checkoutBar={true} />
					)}
				</>
			) : null}
			{!singleBrandEnabled && !showDropdown && (
				<View style={[{ display: showDropdown ? 'none' : 'flex' }]}>
					<NavFooter
						navigation={navigation}
						isMenu={true}
						isSearchActive={isSearchActive}
						onCancelSearch={() => {
							toggleDropdown()
							dispatch(setMenuState({ isSearchActive: false, searchText: '' }))
						}}
					/>
				</View>
			)}
		</>
	)
}

export default BrandMenu
